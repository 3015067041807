
export const ormOptions = {
  "autoImports": [],
  "model": {
    "namespace": "",
    "withMeta": false,
    "hidden": [
      "_meta"
    ],
    "visible": [
      "*"
    ]
  },
  "cache": {
    "shared": true
  }
}
        